<template>
  <ul class="flex flex-col">
    <li class="text-black text-xl font-semibold p-2 mt-2">
      Feedbacks
    </li>
    <li class="flex flex-col w-full mb-2 rounded border card">
      <a
        @click.prevent="
          $emit(
            'current',
            current === `${$options.name}` ? null : `${$options.name}`
          )
        "
        href=""
        class="bg-gray-100 pl-3 pr-3 pt-3 pb-3 text-sm font-semibold card-header"
        >Feedback</a
      >
      <div
        v-if="printing || current === $options.name"
        class="flex flex-col w-full p-2"
      >
        <div class="flex self-end space-x-2">
          <button
            class="rounded-sm px-2 py-1 bg-blue-600 hover:bg-blue-800 text-white save-btn-small"
            @click.prevent="addFeedback"
          >
            Feedback hinzufügen
          </button>
        </div>

        <div
          class="p-2"
          v-for="(row, index) in $v.data.$each.$iter"
          :key="`data-${index}`"
        >
          <div class="flex justify-between">
            <select
              v-model="row.categorie.$model"
              class="px-2 py-1 mb-2 border rounded"
              :class="{ 'border-red-600': row.categorie.$error }"
              :disabled="data[index].read_only"
            >
              <option value="">Kategorie auswählen</option>
              <option
                v-for="(category, index) in categories"
                :key="`category-${index}`"
                :value="category.id"
                >{{ category.categorie }}</option
              >
            </select>

            <button
              class="rounded-sm w-6 h-6 bg-red-600 text-white flex justify-center items-center"
              @click.prevent="data.splice(index, 1)"
              v-if="!data[index].read_only && index !== '0'"
              :disabled="data[index].read_only"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>

          <vue-editor
            v-model="row.feedback.$model"
            :disabled="data[index].read_only"
            :class="{ 'border-red-600': row.feedback.$error }"
            :editorToolbar="[
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [
                { align: 'left' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
              ],
            ]"
          />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'

const tempFeedback = {
  categorie: '',
  feedback: '',
  disabled: false,
}

export default {
  name: 'review-feedback',
  props: [
    'rows',
    'current',
    'scales',
    'isAssesment',
    'who',
    'marks',
    'categories',
  ],
  data() {
    return {
      data: [],
    }
  },
  validations: {
    data: {
      $each: {
        categorie: {
          required,
        },
        feedback: {
          required,
        },
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const rows = _.map(this.rows, row => ({
        ...row,
        //disabled: this.isAssesment,
      }))
      this.data = rows.length ? [...rows] : [{ ...tempFeedback }]
    },
    addFeedback() {
      this.data.push({
        ...tempFeedback,
      })
    },
  },
  watch: {
    data: {
      handler: function(n) {
        this.$v.$touch()
        this.$emit('error', {
          key: 'feedbacks',
          value: this.$v.$invalid,
          error: 'Bitte geben Sie ein oder mehrere Feedbacks an',
        })
        this.$emit('update', { key: 'feedbacks', value: n })
      },
      deep: true,
    },
  },
}
</script>

<style>
.card-header {
  background: none;
  background-color: #e9e9e9;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}

.card {
  box-shadow: 0 1px 1px 1px rgb(33 36 55 / 3%);
  border-radius: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
  border-bottom: 5px solid #f0f0f0;
}
</style>
